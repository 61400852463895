import { WindowLocation } from "@reach/router"
import { Seo } from "components/fragments/Seo/Seo"
import { InsightsView } from "components/views/InsightsView/InsightsView"
import { ContentfulInsight } from "components/views/InsightView/InsightView"
import { graphql } from "gatsby"
import { useState } from "react"
import { useFlexSearch } from "react-use-flexsearch"

interface Location extends WindowLocation {
  state: {
    fromCategory?: {
      id: string
      name: string
    }
  }
}

interface InsightCategoryProps {
  data: {
    allContentfulNews: {
      nodes: ContentfulInsight[]
    }
    localSearchPages: {
      index: unknown
      store: unknown
    }
  }
  location: Location
  pageContext: {
    id: string
    metaDescription: string
    metaTitle: string
    name: string
    category: {
      id: string
      name: string
      slug: string
    }
    limit: number
    skip: number
    numPages: number
    currentPage: number
    channel: string
  }
}

const InsightCategory = ({ data, pageContext, location }: InsightCategoryProps): JSX.Element => {
  const { index, store } = data.localSearchPages
  const { metaDescription, metaTitle, category, numPages, currentPage } = pageContext

  const { search } = location

  const query = new URLSearchParams(search).get("s")
  const [searchQuery, setSearchQuery] = useState(query || "")

  const searchResults = useFlexSearch(searchQuery, index, store)

  const filteredSearchResults = searchResults.filter((insight: ContentfulInsight) => {
    if (!insight.categories) return false
    const categoryIds = insight.categories.map((cat) => cat.id)
    return categoryIds.includes(category.id)
  })

  const insights = searchQuery ? filteredSearchResults : data.allContentfulNews.nodes

  return (
    <>
      <Seo pathname={location.pathname} description={metaDescription} title={metaTitle} />
      <InsightsView
        insights={insights}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        category={{ name: pageContext.name, id: pageContext.id }}
        currentPage={currentPage}
        numPages={numPages}
        paginationBase={`${process.env.GATSBY_SITE_INSIGHTS_URL}/category/${category.slug}`}
      />
    </>
  )
}

export const query = graphql`
  query ($ids: [String]!, $skip: Int!, $limit: Int!, $channel: String!) {
    localSearchPages {
      index
      store
    }
    allContentfulNews(
      filter: {
        id: { in: $ids }
        hidden: { ne: true }
        categories: {
          elemMatch: { metadata: { tags: { elemMatch: { contentful_id: { eq: $channel } } } } }
        }
        metaNoIndexNoFollow: { ne: true }
      }

      sort: { fields: publishDate, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        ...ContentfulNews
      }
    }
  }
`

export default InsightCategory
